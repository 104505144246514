/** Regular */
@font-face {
  font-family: "Calibre-Regular";
  font-weight: 400;
  src: url("./static/fonts/Calibre-Regular.otf");
}

/** Bold */
@font-face {
  font-family: "Calibre-Bold";
  font-weight: 600;
  src: url("./static/fonts/Calibre-Bold.otf");
}

/** Medium */
@font-face {
  font-family: "Calibre-Medium";
  font-weight: 500;
  src: url("./static/fonts/Calibre-Medium.otf");
}

/** Semibold */
@font-face {
  font-family: "Calibre-SemiBold";
  font-weight: 200;
  src: url("./static/fonts/Calibre-Semibold.otf");
}

/** Bold */
@font-face {
  font-family: "Gilroy-Bold";
  font-weight: 600;
  src: url("./static/fonts/Gilroy-Bold.ttf");
}

/** Semibold */
@font-face {
  font-family: "Gilroy-SemiBold";
  font-weight: 600;
  src: url("./static/fonts/Gilroy-SemiBold.otf");
}

/** Regular */
@font-face {
  font-family: "Radomir-Tinkov-Gilroy-Regular";
  font-weight: 400;
  src: url("./static/fonts/Radomir-Tinkov-Gilroy-Regular.otf");
}

/** Bold */
@font-face {
  font-family: "SF-Display-Bold";
  font-weight: 700;
  src: url("./static/fonts/SFUIDisplay-Bold.otf");
}

/** Light */
@font-face {
  font-family: "SF-Display-Light";
  font-weight: 200;
  src: url("./static/fonts/SFUIDisplay-Light.otf");
}

/** Medium */
@font-face {
  font-family: "SF-Display-Medium";
  font-weight: 500;
  src: url("./static/fonts/SFUIDisplay-Medium.otf");
}

/** Regular */
@font-face {
  font-family: "SF-Display-Regular";
  font-weight: 400;
  src: url("./static/fonts/SFUIDisplay-Regular.otf");
}

/** Semibold */
@font-face {
  font-family: "SF Display";
  font-weight: 600;
  src: url("./static/fonts/SFUIDisplay-Semibold.otf");
}

/** Medium */
@font-face {
  font-family: "Palyfair-Medium";
  font-weight: 500;
  src: url("./static/fonts/PlayfairDisplay-Medium.ttf");
}

/** Regular */
@font-face {
  font-family: "Palyfair-Regular";
  font-weight: 400;
  src: url("./static/fonts/PlayfairDisplay-Regular.ttf");
}

/** Semibold */
@font-face {
  font-family: "Playfair Display";
  font-weight: 600;
  src: url("./static/fonts/PlayfairDisplay-Bold.ttf");
}

/** Regular */
@font-face {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  src: url("./static/fonts/OpenSans-Regular.ttf");
}

/** Semibold */
@font-face {
  font-family: "OpenSans-Regular";
  font-weight: 600;
  src: url("./static/fonts/OpenSans-SemiBold.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
:root {
  font-size: 100%;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #000000;
  font-weight: 400;
  max-width: 100vw;
  font-family: "Calibre-Regular";
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

table,
button,
input {
  font: inherit;
}

a {
  color: currentColor;
  text-decoration: none;
}

input:focus,
select:focus {
  outline: none;
}

button {
  cursor: pointer;
}

textarea,
select,
input,
button {
  outline: none;
}

input.fullWidth {
  width: 100%;
}

.fontBold {
  font-weight: bold;
}
.fontLight {
  font-weight: lighter;
}
.txtAlignRight {
  text-align: right;
}

.details {
  flex: 0 0 30%;
  margin: 20px 0 20px 20px;
}

.is-active {
  text-decoration: none;
  color: gray !important;
  font-weight: 700;
}

.is-activeSmallDrawer {
  text-decoration: none;
  border-left: 5px solid #6eb3ff;
  color: #fff !important;
  padding-left: 10px 10px 0 13px !important;
}

.menuButton {
}

.hide {
  display: none;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #dcdcdc;
}
.separator::before {
  margin-right: 0.25em;
}
.separator::after {
  margin-left: 0.25em;
}

.itemsCarousel {
  width: 100%;
}

.image-container {
  background-color: #ffffff;
  width: 300px;
  height: 400px;
}

.carouselContainer {
  margin: 0 auto;
  display: flex;
  background-color: #ffffff;
}

.loader {
  /* position: absolute;
  top: 45%;
  left: 50%;
  margin-top: 12px;
  margin-left: 12px; */
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.react-datepicker {
  width: 100%;
}

.shiiping-add .MuiOutlinedInput-multiline {
  padding: 17.5px 10px;
  text-transform: capitalize;
}

.loading {
  position: fixed;
  top: 45%;
  left: 48%;
  background-color: transparent;
  z-index: 9999;
}

.justifiyCotent {
  justify-content: center;
}

.is-sticky {
  position: fixed;
  top: 141px;
  /* top: 174px */
  left: 0;
  width: 100%;
  z-index: 999;
  /* box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%); */
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

.is-stickyPreview {
  position: fixed;
  top: 41px;
  left: 0;
  width: 100%;
  z-index: 999;
  /* box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%); */
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

.is-stickytwo {
  position: fixed;
  top: 41px;
  /* margin-top: 60px; */
  left: 0;
  width: 100%;
  z-index: 999;
  /* box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%); */
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

@media (min-width: 1024px) {
  .is-sticky {
    top: 90px;
  }
  .is-stickyPreview {
    top: 40px;
  }
  .is-stickytwo {
    top: 41px;
    padding: 0 0 0 262px;
  }
}

#wrapfream {
  width: 454px;
  height: 262px;
  padding: 0;
  margin-left: 60px;
  overflow: hidden;
}

#scaled-frame {
  border: 0px;
}

#scaled-frame {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #scaled-frame {
    zoom: 1.5;
  }
}

@media screen and (max-width: 600px) {
  #wrapfream {
    width: 487px;
    height: 300px;
    margin-left: 100px;
  }
  #scaled-frame {
    /* zoom: 1.2; */
    -webkit-transform: scale(0.7);
    -webkit-transform-origin: 0 0;
  }
}

@media (width: 768px) {
  #wrapfream {
    width: 494px;
    height: 300px;
  }
}

#wrapfreamtender {
  width: 404px;
  height: 270px;
  padding: 0px;
  overflow: hidden;
  margin-left: 50px;
}

#scaled-frametender {
  border: 0px;
}

#scaled-frametender {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #scaled-frametender {
    zoom: 1.2;
  }
}

@media screen and (max-width: 600px) {
  #wrapfreamtender {
    width: 460px;
    height: 238px;
    margin-left: 160px;
    padding: 2px;
  }
  #scaled-frametender {
    -webkit-transform: scale(0.5);
    -webkit-transform-origin: 0 0;
  }
}

@media (width: 768px) {
  #wrapfreamtender {
    width: 494px;
    height: 300px;
    margin-left: 80px;
  }
}

#wrapfreamDetailsCarousel {
}
#new-wrapfreamDetailsCarousel {
}

#scaled-frameDetailsCarousel {
  border: 0px;
}

#new-scaled-frameDetailsCarousel {
  border: 0px;
}

#scaled-frameDetailsCarousel {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}

#new-scaled-frameDetailsCarousel {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}

@media screen and (max-width: 600px) {
  #wrapfreamDetailsCarousel {
    width: 240px;
    height: 250px;
    margin-left: 60px;
    padding: 0px;
  }
  #new-wrapfreamDetailsCarousel {
    width: 240px;
    height: 250px;
    margin-left: 30px;
    padding: 0px;
  }
  #scaled-frameDetailsCarousel {
    zoom: 1.75;
    -webkit-transform: scale(0.5);
    -webkit-transform-origin: 0 0;
  }
  #new-scaled-frameDetailsCarousel {
    zoom: 1.75;
    -webkit-transform: scale(0.5);
    -webkit-transform-origin: 0 0;
  }
}

@media (min-width: 700px) and (max-width: 959.95px) {
  #new-scaled-frameDetailsCarousel {
    zoom: 1.75;
    -webkit-transform: scale(0.85);
  }
}

@media (width: 768px) {
  #wrapfreamDetailsCarousel {
    width: 494px;
    height: 300px;
  }
  #new-wrapfreamDetailsCarousel {
    width: 494px;
    height: 300px;
  }
}

@media (width: 820px) {
  #new-wrapfreamDetailsCarousel {
    width: 501px;
    height: 449px;
  }
}
.AccordionHeaderscroll {
  position: fixed;
  top: 30px;
  width: 100%;
  z-index: 999;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: width 1s;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

@media screen and (max-width: 600px) {
  .AccordionHeaderscroll {
    top: 35px;
  }
}

#rc-anchor-container {
  border: none;
  box-shadow: none;
  background: none;
}

#wrapfreamNew {
  width: 454px;
  height: 262px;
  padding: 0;
  margin-left: 60px;
  overflow: hidden;
}

@media (width: 820px) {
  #wrapfream {
    width: 585px;
    height: 331px;
    padding: 0;
    margin-left: 60px;
  }
  #scaled-frame {
    zoom: 2;
  }
}
@media screen and (max-width: 600px) {
  #wrapfreamNew {
    width: 487px;
    height: 300px;
    margin-left: 100px;
  }
}
@media screen and (width: 1024px) {
  #wrapfream {
    width: 574px;
    height: 360px;
    padding: 0;
    margin-left: 59px;
    overflow: hidden;
  }
}
@media screen and (width: 912px) {
  #wrapfream {
    width: 594px;
    height: 359px;
    padding: 0;
    margin-left: 60px;
  }
}
@media (width: 768px) {
  #wrapfreamNew {
    width: 494px;
    height: 300px;
  }
}

@media (width: 375px) {
  #wrapfreamNew {
    width: 477px;
    height: 300px;
    margin-left: 100px;
  }
}

@media (min-width: 1850px) and (max-width: 1980px) {
  #wrapfream {
    width: 526px;
    height: 300px;
    padding: 0;
    margin-left: 86px;
  }
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}
